import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import menuIco from "../../icons/menu.png";
import closeIco from "../../icons/closemenu.png";
import envelopeIco from "../../icons/envelope.png";
import styles from "../DropDownMenu/DropDownMenu.module.css";
import AccauntMenu from "../../components/userSetting/userSetting";
import LanguageButton2 from "../button/languageButton2/langageButton2";
import { useTranslation } from "react-i18next";
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import { Margin } from "@mui/icons-material";


export default function BasicMenu({scrollToBottomm}) {
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const handleClose = (option) => {
    if(option === "rent") {
        navigate("/search");
        localStorage.setItem("filter", "rental");
    } else if(option === "search") {
        navigate("/search");
    } else if(option === "map") { 
      navigate("/map")
    localStorage.setItem("comingfrom","header");

    } else if(option === "favorites") { 
        navigate("/favorites")
    localStorage.setItem("comingfrom","header");

    } 

    setAnchorEl(null);
    setIsOpen(!isOpen);

  };

  // Check if authToken exists in localStorage
  const authToken = localStorage.getItem("authToken");

  return (
    <div>
      <div
      id="custom-button"
      aria-controls={isOpen ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? 'true' : undefined}
      onClick={handleClick}
      className={styles.buttonRoot} 
    >
      <img
        src={isOpen ? closeIco : menuIco}
        alt={isOpen ? 'Close' : 'Menu'}
        className={styles.image}
      />
    </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          'fontFamily': 'Poppins, sans-serif',
        }}
      >
        <MenuItem className={styles.GroupBox1}>
          <AccauntMenu />
          {authToken && <label>{user.name} {user.surname}</label>}
          
        </MenuItem>
        <hr style={{ margin: 0 }} />

          <MenuItem >        
            <span className={styles.SavedButton}><i class="bi bi-translate" style={{ fontSize: "1.3rem" }}></i></span>
            <LanguageButton2 />
            
          </MenuItem>
          <hr style={{ margin: 0 }} />

        <MenuItem onClick={() => handleClose("rent")}>{t("Rent Apartment")}</MenuItem>
        <MenuItem onClick={() => handleClose("search")}>{t("Search")}</MenuItem>
        <MenuItem onClick={() => handleClose("map")}>{t("Map")}</MenuItem>
        <MenuItem onClick={() => {handleClose("apartment"); scrollToBottomm();}}>{t("Apartments")}</MenuItem>
        <MenuItem onClick={() => handleClose("favorites")}>{t("Favorites")}</MenuItem>
      </Menu>
    </div>
  );
}
