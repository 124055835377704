import React, { useState, useEffect } from "react";
import styles from "./Detail.module.css";
import hederImg from "../../img/hederIco.jpg";
import backIco from "../../../src/icons/back.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Rating from '@mui/material/Rating';
import Carousel from '../../components/carousel/Carousel';
import Avatar from "@mui/material/Avatar";
import { CircularProgress, Box } from '@mui/material';
import { use } from "react";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";


const DetailPage = () => {
  const { id } = useParams();
  const [apartment, setApartment] = useState(null);
  const [user, setUser] = useState(null);
  const [loadingApartment, setLoadingApartment] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  const [isFavorited, setIsFavorited] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertiesStatus, setPropertiesStatus] = useState("idle");
  const [error, setError] = useState(null);
 const [center, setCenter] = useState([40.4093, 49.8671]);

 delete L.Icon.Default.prototype._getIconUrl;
 L.Icon.Default.mergeOptions({
   iconRetinaUrl:
     "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
   shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
 });
  
  const handlePhoneCall = () => {
    window.location.href = `tel:${user.phone_num}`;
  };


  const handleAddToFav = async () => {
    try {
     
      const user = JSON.parse(localStorage.getItem('user'));
  
      if (!user) {
        console.error('No user found in local storage');
        alert('Please log in to manage favorites.');
        return;
      }
     const favApartments = user.fav_apartments || []; 
      const updatedFavApartments = favApartments.includes(apartment.id)
        ? favApartments.filter((id) => id !== apartment.id) 
        : [...favApartments, apartment.id]; 
  
    
      const updatedUser = {
        ...user,
        fav_apartments: updatedFavApartments, 
      };
  
      
      const response = await fetch(`https://api.nextflat.my/update-users/${user.id}`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser), 
      });
  
      if (!response.ok) {
        throw new Error(`Error updating user: ${response.statusText}`);
      }
  
      
      localStorage.setItem('user', JSON.stringify(updatedUser));
      // alert(
      //   favApartments.includes(apartment.id)
      //     ? 'Apartment removed from favorites!'
      //     : 'Apartment added to favorites!'
      // );
    } catch (error) {
      console.error('Error toggling favorites:', error);
      alert('Failed to update favorites. Please try again.');
    }
    window.location.reload(); 

};


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]);
        },
        (error) => {
          console.error("Error fetching geolocation:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
    i18n.changeLanguage(lang); 
    
  }, [i18n, lang]);

  useEffect(() => {
   
    if (id) {
      fetch(`https://api.nextflat.my/apartments/${id}`)
        .then((response) => response.json())
        .then((data) => {
          setApartment(data);
          setLoadingApartment(false);

          if (data.author_id) {
            fetch(`https://api.nextflat.my/users/${data.author_id}`)
              .then((response) => response.json())
              .then((userData) => {
                setUser(userData);
              
                setLoadingUser(false);
              })
              .catch((error) => {
                console.error("Error fetching user:", error);
                setLoadingUser(false);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching apartment:", error);
          setLoadingApartment(false);
        });
      
    }
    
  }, [id]);



  useEffect(() => {
    const fetchUserAndCheckFavorites = async () => {
    const user = JSON.parse(localStorage.getItem("user")); 

      try {
        const userResponse = await fetch(`https://api.nextflat.my/users/${user.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!userResponse.ok) {
          throw new Error(`Failed to fetch user data: ${userResponse.statusText}`);
        }

        const userData = await userResponse.json();
        console.log("User Data:", userData);

        const { fav_apartments } = userData;

        if (fav_apartments && fav_apartments.includes(apartment?.id)) {
          
          
          const updatedFavApartments = [...fav_apartments, apartment.id];
          const updatedUserData = { ...userData, fav_apartments: updatedFavApartments };
  
          localStorage.setItem("user", JSON.stringify(updatedUserData));
  
          console.log("Updated User Data:", updatedUserData);

          setIsFavorited(true);
        } else {
          const updatedFavApartments = [...fav_apartments, ];
          const updatedUserData = { ...userData, fav_apartments: updatedFavApartments };
  
          localStorage.setItem("user", JSON.stringify(updatedUserData));
  
          console.log("Updated User Data:", updatedUserData);
          setIsFavorited(false);
        }
      } catch (err) {
        console.error("Error during data fetching:", err);
        setError(err.message);

      }
    };

    fetchUserAndCheckFavorites();
  }, [user?.id, apartment?.id]);

  const fetchUserDataAndFavorites = async () => {
 
    try {
      setPropertiesStatus("loading");

      const userResponse = await fetch(`https://api.nextflat.my/users/${user.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!userResponse.ok) {
        throw new Error(`Failed to fetch user data: ${userResponse.statusText}`);
      }

      const userData = await userResponse.json();
      console.log("User Data:", userData);

      const { fav_apartments } = userData;

      if (!fav_apartments || fav_apartments.length === 0) {
        console.log("No favorite apartments found.");
        setPropertiesStatus("no_favorites");
        return;
      }

      const favoriteProperties = await Promise.all(
        fav_apartments.map(async (id) => {
          const apartmentResponse = await fetch(`https://api.nextflat.my/apartments/${id}`);
          if (!apartmentResponse.ok) {
            throw new Error(`Failed to fetch apartment with ID: ${id}`);
          }
          return apartmentResponse.json();
        })
      );

      setProperties(favoriteProperties);
      
      setPropertiesStatus("successful");
    } catch (error) {
      console.error("Error during data fetching:", error);
      setError(error.message);
      setPropertiesStatus("failed");
 
    }
  };

  useEffect(() => {
    fetchUserDataAndFavorites();
  }, []);

  const homereturn = () => {
    navigate("/");
  };



  if (loadingApartment) {
    return  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh', // Center vertically in the viewport
    }}
  >
    <CircularProgress size={60} sx={{ color: 'goldenrod' }} />
  </Box>  ;
  }

  if (!apartment) {
    return <div>Apartment not found</div>;
  }

  const BackButtton =()=> {
    const navigateto = localStorage.getItem("comingfrom")||"main";
    navigate(`/${navigateto}`); 
  };
  const openGoogleMaps = (address) => {
    if (!address) {
      alert("Address not available");
      return;
    }
  
    // Format the address for the Google Maps query string
    const formattedAddress = encodeURIComponent(address);
  
    // Construct the URL
    const mapsUrl = `https://www.google.com/maps?q=${formattedAddress}`;
  
    // Open Google Maps in a new tab
    window.open(mapsUrl, "_blank", "noopener,noreferrer");
  };
  return (
    <div className={styles.mainC}>
      <div className={styles.mainB}>
        <div className={styles.headerContainer}>
          <div className={styles.heder}>
            <img
              src={hederImg}
              alt="Next Flat"
              className={styles.animated_image}
              onClick={homereturn}
            />
          </div>
          <hr className={styles.bottomHR} />
        </div>
        <div className={styles.top}>
          <button
            className={styles.backbtn}
            onClick={BackButtton}
          >
            <img className={styles.backIco} alt="" src={backIco} />
            {t("Back")}
          </button>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.mainImage}>
            <Carousel images={apartment.images} />
          </div>
        </div>
        <div className={styles.right}>
          <span className={styles.appartmentName}>{apartment.title}</span>
          <div className={styles.userDetail}>
            <Avatar src={user?.profile_image} sx={{ width: 50, height: 50 }} alt={user?.name} />
            <div className={styles.cityInfo}>
              <span className={styles.userDetailName} onClick={()=>navigate(`/userDetail/${user.id}`)}>
                {user ? `${user.name} ${user.surname}` : t("Owner Name")}
              </span>
              <span className={styles.userDetailCity}>
                {t("Country")}: {user?.country || t("N/A")}
              </span>
              <Rating
                name="read-only"
                value={(user?.ratings?.length > 0 ? user?.ratings.reduce((sum, rating) => sum + rating, 0) / user?.ratings.length : 5) || 5}
                readOnly
                sx={{ fontSize: "16px" }}
              />
            </div>
          </div>
          <span className={styles.descripText}>
            {apartment.description || t("No Description Available.")}
          </span>
          <div className={styles.btnGroup}>
            <button className={styles.btnGroupCall} onClick={handlePhoneCall}>
              <i className="bi bi-telephone"></i> {user ? user.phone_num : t("Call")}
            </button>
            <button
      className={`${styles.btnGroupBasket} ${isFavorited ? styles.active : ''}`}
      onClick={handleAddToFav}
    >
      <i className={`bi ${isFavorited ?  'bi-heart-fill':'bi-heart'}`}></i>
      {isFavorited}
    </button>
          </div>
          <div className={styles.appartmentpriceandvalute}>
            <span className={styles.appartmentprice}>
              {apartment.price || t("N/A")}
            </span>
            <span className={styles.appartmentpricevalute}>
            {apartment.currency || t("AZN")}
            </span>
          </div>
        </div>
      </div>
       <div className={styles.footer}>
      <span><i className="bi bi-globe"></i><span className={styles.label}>{t("Country")}:</span> <span className="data">{apartment.country}</span></span>
<span><i className="bi bi-geo-alt"></i><span className={styles.label}>{t("City")}:</span> <span className="data">{apartment.city}</span></span>
<span  onClick={() => openGoogleMaps(apartment.street)}  ><i className="bi bi-signpost-2"></i><span className={styles.label} >{t("Street")}:</span> <span className={`data ${styles.streetspan}`} >{apartment.street}</span></span>
<span><i className="bi bi-house"></i><span className={styles.label}>{t("Service Type")}:</span> <span className="data">{t(apartment.serviceType)}</span></span>
<span><i className="bi bi-building"></i><span className={styles.label}>{t("Type of Home")}:</span> <span className="data">{t(apartment.typeOfHome)}</span></span>
<span><i className="bi bi-cash"></i><span className={styles.label}>{t("Payment Options")}:</span> <span className="data">{t(apartment.paymentMethod)}</span></span>
<span><i className="bi bi-door-closed"></i><span className={styles.label}>{t("Rooms")}:</span> <span className="data">{apartment.roomNumber}</span></span>
<span><i className="bi bi-rulers"></i><span className={styles.label}>{t("Area (kvm)")}:</span> <span className="data">{apartment.kvmAmount}</span></span>
<span><i className="bi bi-ladder"></i><span className={styles.label}>{t("Floor")}:</span> <span className="data">{apartment.flour}</span></span>


       </div>
       <div className={styles.MapConteiner}>
   <MapContainer
  center={center}
  zoom={9}
  style={{ width: "100%", height: "30em", marginBottom: "2em", }}
  maxBounds={[
    [85, -180],
    [-85, 180],
  ]}
  maxBoundsViscosity={1.0}
>
  {/* Base Map Layer */}
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution="&copy; Uğur Sadıxlı"
  />

  {/* User's Location Marker */}
  <Marker position={center}>
    <Popup>{t("You are here")}</Popup>
  </Marker>

  {/* Single Apartment Marker */}
  {apartment.longitude && apartment.latitude && (
    <>
      <Marker
        position={[apartment.latitude, apartment.longitude]}
        icon={L.icon({
          iconUrl: apartment.image || "https://via.placeholder.com/40",
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          popupAnchor: [0, -40],
        })}
      >
        <Popup>
          <span>{apartment.title}</span>
        </Popup>
      </Marker>

      <Polyline
        positions={[center, [apartment.latitude, apartment.longitude]]}
        color="blue" 
        weight={3} 
        opacity={0.8} 
      />
    </>
  )}
</MapContainer>


       </div>
    </div>
  );
};

export default DetailPage;
