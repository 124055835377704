import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./UserDetail.module.css";
import backIco from "../../../src/icons/back.png";
import hederImg from "../../img/hederIco.jpg";
import Rating from "@mui/material/Rating";
import CircularProgress from "@mui/material/CircularProgress";
import Card  from "../../components/card/card";

const UserDetail = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");
  const [user, setUser] = useState(null);
  const [properties, setProperties] = useState([]);
  const [propertiesStatus, setPropertiesStatus] = useState("idle");
  const [error, setError] = useState(null);
  const [announcementsNum, setAnnouncementsNum] = useState(null);
  const [rating, setRating] = useState(user ? user.ratings : 0);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`https://api.nextflat.my/users/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUser(data);

        fetchUserApartments(data.apartment_ids);
        setAnnouncementsNum(data.apartment_ids.length);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchUserApartments = async (apartmentIds) => {
      setPropertiesStatus("loading");
      try {
        const response = await fetch("https://api.nextflat.my/apartments");
        if (!response.ok) {
          throw new Error("Failed to fetch properties");
        }
        const data = await response.json();
        const userProperties = data.filter(property => apartmentIds.includes(property.id));
        setProperties(userProperties);
        setPropertiesStatus("successful");
      } catch (err) {
        setError(err.message);
        setPropertiesStatus("failed");
      }
    };

    fetchUser();
  }, [id]);

  const handleNavigate = () => {
    navigate("/"); 
  };

  const handlePhoneCall = () => {
    if (user) {
      window.location.href = `tel:${user.phone_num}`;
    }
  };

  const homereturn = () => {
    navigate(`/main`); 
  };


    const handleRatingChange = async (newValue) => {
    setRating(newValue);

  
    const updatedRatings = user?.ratings ? [...user.ratings, newValue] : [newValue]; 

    try {
      const response = await fetch(
        `https://api.nextflat.my/update-users/${user.id}`,
        {
          method: "PUT", 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_rating: newValue,
            ratings: updatedRatings, 
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Ratings updated successfully:", data);
      } else {
        console.error("Failed to update ratings");
      }
    } catch (error) {
      console.error("Error updating ratings:", error);
    }
  };

  let content;
  if (propertiesStatus === "loading") {
    content = (
      <div>
        <CircularProgress size={60} sx={{ color: "goldenrod" }} />
      </div>
    );
  } else if (propertiesStatus === "successful") {
    content = Array.isArray(properties) ? (
      properties.map((property, index) => (
        <Card
          key={index}
          image={property.image}
          title={property.title}
          author={property.author}
          price={property.price}
          currency={property.currency}
      onClick={() => navigate(`/detail/${property?.id}`)}
      
        />
      ))
    ) : (
      <div>{t("No properties found")}</div>
    );
  } else if (propertiesStatus === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <div className={styles.mainC}>
      <div className={styles.mainB}>
        <div className={styles.headerContainer}>
          <div className={styles.heder}>
            <img
              src={hederImg}
              alt="Next Flat"
              className={styles.animated_image}
              onClick={homereturn}
            />
          </div>
          <hr className={styles.bottomHR} />
        </div>
        <div className={styles.top}>
          <button className={styles.backbtn} onClick={() => navigate("/")}>
            <img className={styles.backIco} alt="" src={backIco} />
            {t("Back")}
          </button>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.userdetails}>
          {user ? (
            <>
              <div className={styles.userdetailsAvaAndRating}>
                <img
                  src={user?.profile_image || "placeholder-avatar.png"}
                  alt="User Avatar"
                  className={styles.avatar}
                />
                <Rating
      name="user-rating"
      value={rating}
      onChange={(event, newValue) => handleRatingChange(newValue)} 
      sx={{ fontSize: "20px" }}
    />
              </div>
              <div className={styles.userdetailsDetails}>
                <span ><strong>{t("Name")}:</strong> {user.name}</span>
                <span ><strong>{t("Surname")}:</strong> {user.surname}</span>
                <span ><strong>{t("Country")}:</strong> {user.country}</span>
                <span  className={styles.userdetailsDetailsCauntry} style={{ textDecoration: 'none' }}><strong>{t("Email")}:</strong> {user.email}</span>
                <span  className={styles.announcementsnumSpan} ><strong>{t("announcements num")}:</strong> {announcementsNum}</span>

                <button className={styles.btnGroupCall} onClick={handlePhoneCall}>
                  <i className="bi bi-telephone"></i> {user.phone_num || t("Call")}
                </button>
              

              </div>
              
            </>
          ) : (
            <div>{t("Loading user details...")}</div>
          )}
        </div>
        {/* <hr className={styles.verticalline} /> */}
          <div className={styles.useranouncementsUserName}>
            <span>{user?.name}{t("'s announcements")}</span>
        <div className={styles.useranouncements}>
          {content}
        </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
